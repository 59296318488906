import * as React from 'react'

import {aspectHide, aspectName} from '../../format/aspects.js'
import {colorScheme, colorSchemes} from '../../format/colorSchemes.js'
import {mapRange} from '../../plugins/common/data.js'
import {Slider} from '../../plugins/common/slider.js'
import {Hr, ItemKV, Section} from '../../plugins/studioInfo/common/section.js'
import {chained} from '../../tools/common.js'

const styles = {
  legend: {
    textAlign: 'center',
  },
  choice: {
    position: 'relative',
    zIndex: 10,
    fontSize: 12,
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    border: 'none',
    outline: 'none',
    textAlign: 'right',
    WebkitTextAlign: 'right',
    MozTextAlign: 'right',
    textAlignLast: 'right',
    WebkitTextAlignLast: 'right',
    MozTextAlignLast: 'right',
    background: 'transparent',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'none\' stroke=\'black\' stroke-width=\'2\' height=\'24\' width=\'24\' viewBox=\'0 0 24 24\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5\'/><path d=\'M0 0h24v24H0z\' fill=\'none\' stroke=\'none\'/></svg>")',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'calc(100% + 6px)',
    backgroundPositionY: -5,
    paddingRight: 16,
  },
  sliderAttached: {
    width: 254,
    margin: '-26px auto -9px',
  },
  slider: {
    width: 254,
    margin: '-26px auto -2px',
  },
  sliderHandleAttached: {
    height: 9,
    backgroundColor: '#666',
  },
  sliderHandle: {
    height: 12,
    backgroundColor: '#666',
  },
  sliderBar: {
    height: 6,
    backgroundColor: '#aaa',
  },
  sliderLine: {
    margin: '0 7px',
    height: 6,
  },
  gradientColorKey: {
    textAlign: 'center',
    marginBottom: 10,
  },
  item: {
    width: 240,
    margin: '0 auto 0 auto',
  },
  itemFirst: {
    marginTop: 8,
  },
  itemLast: {
    marginBottom: 7,
  },
  checkboxLabel: {
    position: 'relative',
    top: -2,
  },
  checkboxInput: {
    marginLeft: 0,
  },
}

const SliderAspect = props => <Slider
    range={[0, 1]}
    step={.01}
    from={props.dataRangeRelative[0]}
    to={props.dataRangeRelative[1]}
    onChange={(from, to) => props.changeDataRangeRelative(props.data, [from, to])}
    style={props.attached ? styles.sliderAttached : styles.slider}
    styleHandle={props.attached ? styles.sliderHandleAttached : styles.sliderHandle}
    styleBar={styles.sliderBar}
    styleLine={styles.sliderLine}
    disabled={props.disabled}
    fromMax={props.range && props.range[0] < 0 && 0 < props.range[1] ? Math.max(0, -.05 + (0 - props.range[0]) / (props.range[1] - props.range[0])) : null}
    toMin={props.range && props.range[0] < 0 && 0 < props.range[1] ? Math.min(1, .05 + 1 - (props.range[1] - 0) / (props.range[1] - props.range[0])) : null}
    options={{
      type: 'double',
      drag_interval: true,
      min_interval: .05,
      hide_min_max: true,
      hide_from_to: true,
    }}
  />

class Gradient extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 240,
      height: 20,
      padding: 20,
    }
    this._setGradient = this._setGradient.bind(this)
  }
  _setGradient(gradient) {
    this._gradient = gradient
  }
  componentDidMount() {
    this.redraw()
  }
  componentDidUpdate(prevProps, prevStats, snapshot) {
    for (const k of ['k', 'range', 'mappedRange', 'opacity']) if (prevProps[k] != this.props[k]) {
      this.redraw()
      continue
    }
  }
  redraw() {
    const t = this
    // remove old element
    d3.select(t._gradient).selectAll('*').remove()
    if (!t.props.range || !t.props.mappedRange) return
    // preparations
    let min = 0
    let max = 1
    if (t.props.range && t.props.range.length == 2) [min, max] = t.props.range
    const widthDelta = 1
    const as = d3.range(min, max, (max - min) / (t.state.width / widthDelta))
    // add new element
    const svg = d3.select(t._gradient).append('svg')
      .attr('height', t.state.height + 11)
      .attr('width', t.state.width + 2 * t.state.padding)
      .style('margin-left', -20)
      .style('margin-right', -20)
    const xScale = d3.scaleLinear().domain([min, max]).range([0, t.state.width])
    const colorScale = colorScheme(t.props.colorScheme).scheme(...t.props.mappedRange)
    svg.selectAll('rect').data(as).enter()
      .append('rect')
      .attr('x', d => t.state.padding + xScale(d))
      .attr('y', 0)
      .attr('height', t.state.height)
      .attr('width', widthDelta)
      .attr('fill', colorScale)
      .attr('fill-opacity', t.props.opacity)
    svg.selectAll('text').data([min, max, (max + min) / 2]).enter()
      .append('text')
      .attr('x', d => t.state.padding + xScale(d))
      .attr('y', t.state.height + 11)
      .attr('font-family', '"Helvetica Neue", Helvetica, Arial, sans-serif')
      .attr('font-size', '10px')
      .attr('text-anchor', 'middle')
      .text(d => d.toFixed(1))
  }
  render() {
    return <div ref={this._setGradient}/>
  }
}

export class SectionLegend extends React.Component {
  render() {
    const t = this
    let content = 'legend only available for grid layers'
    if (chained(t.props.data, 'aggregateByGrid') == true) {
      const dataKeys = chained(t.props.data, '_dataKeys', []).filter(k => !aspectHide(k))
      const c = chained(t.props.data, 'dataRange')
      const rangeColor = t.props.data.aspectColor && c && c[t.props.data.aspectColor] !== undefined ? c[t.props.data.aspectColor].color : null
      const rangeSize = t.props.data.aspectSize && c && c[t.props.data.aspectSize] !== undefined ? c[t.props.data.aspectSize].size : null
      const dataRangeRelativeColor = t.props.data.dataRangeRelative && t.props.data.dataRangeRelative[t.props.data.aspectColor] && t.props.data.dataRangeRelative[t.props.data.aspectColor].color ? t.props.data.dataRangeRelative[t.props.data.aspectColor].color : [0, 1]
      const dataRangeRelativeSize = t.props.data.dataRangeRelative && t.props.data.dataRangeRelative[t.props.data.aspectSize] && t.props.data.dataRangeRelative[t.props.data.aspectSize].size ? t.props.data.dataRangeRelative[t.props.data.aspectSize].size : [0, 1]
      const mappedRangeColor = rangeColor ? mapRange(rangeColor, dataRangeRelativeColor) : null
      content = <div>
        <div style={styles.legend}>
          <ItemKV key="aspectColor" style={{...styles.item, ...styles.itemFirst}} k="Cell colour" v={
            <select
              style={styles.choice}
              value={t.props.data.aspectColor}
              onChange={e => this.props.datasetManager.changeAspectColor(t.props.data, e.target.value)}
            >
              {dataKeys.map(k => <option key={k} value={k}>{aspectName(k)}</option>)}
            </select>}
          />
          <ItemKV key="colorScheme" style={{...styles.item, ...styles.itemLast}} k="Colour scheme" v={
            <select
              style={styles.choice}
              value={t.props.data.colorScheme}
              onChange={e => this.props.datasetManager.changeColorScheme(t.props.data, e.target.value)}
            >
              {Object.values(colorSchemes).flatMap(vs => vs.map(v => <option key={v.id} value={v.id}>{v.name}</option>))}
            </select>}
          />
          <SliderAspect
            attached={true}
            dataRangeRelative={dataRangeRelativeColor}
            range={rangeColor}
            data={t.props.data}
            changeDataRangeRelative={(d, range) => t.props.datasetManager.changeDataRangeRelativeColor(d, range)}
          />
          <Gradient
            range={rangeColor}
            mappedRange={mappedRangeColor}
            opacity={t.props.cellColorOpacity}
            colorScheme={t.props.data.colorScheme}
          />
        </div>
        <ItemKV style={{...styles.item, ...styles.itemFirst, ...styles.itemLast}} k="Cell size" v={
          <select
            style={styles.choice}
            value={t.props.data.aspectSize}
            onChange={e => this.props.datasetManager.changeAspectSize(t.props.data, e.target.value)}
          >
            {[['null', 'none'], ...dataKeys.map(k => [k, aspectName(k)])].map(([k, aspectNameK]) => <option key={k} value={k}>{aspectNameK}</option>)}
          </select>
        }/>
        <SliderAspect
          attached={false}
          dataRangeRelative={dataRangeRelativeSize}
          range={rangeSize}
          data={t.props.data}
          disabled={t.props.data.aspectSize == 'null'}
          changeDataRangeRelative={(d, range) => t.props.datasetManager.changeDataRangeRelativeSize(d, range)}
        />
        <Hr/>
        <div key="showGridContour">
          <input
            type="checkbox"
            id="showGridContour"
            name="showGridContour"
            defaultChecked={t.props.showGridContour}
            onChange={e => t.props.datasetManager.changeShowGridContour(e.target.checked)}
            style={styles.checkboxInput}
          />
          <label htmlFor="showGridContour" style={styles.checkboxLabel}>show contour of grid cells</label>
        </div>
        <div key="showGridCentroid">
          <input
            type="checkbox"
            id="showGridCentroid"
            name="showGridCentroid"
            defaultChecked={t.props.showGridCentroid}
            onChange={e => t.props.datasetManager.changeShowGridCentroid(e.target.checked)}
            style={styles.checkboxInput}
          />
          <label htmlFor="showGridCentroid" style={styles.checkboxLabel}>show centroid of grid cells</label>
        </div>
      </div>
    }
    return <Section title="Legend and Visualization" usePages={true} {...t.props}>
      {content}
    </Section>
  }
}
