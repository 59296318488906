L.IconRed = L.Icon.Default.extend({
  options: {
    imagePath: 'images/leaflet-icon/',
  },
})

L.iconRed = options => new L.IconRed(options)

L.StudioGeocode = L.Class.extend({
  options: {
    position: 'topleft',
  },
  initialize: function(options) {
    L.Util.setOptions(this, options)

    let geocodeMarker = null

    this._controlGeocoder = L.Control.geocoder({defaultMarkGeocode: false, position: this.options.position})
      .on('markgeocode', e => {
        e.target._map.fitBounds(e.geocode.bbox)
        if (geocodeMarker) e.target._map.removeLayer(geocodeMarker)
        geocodeMarker = new L.marker(e.geocode.center, {icon: L.iconRed()})
          .bindPopup(e.geocode.html || e.geocode.name)
          .addTo(e.target._map)
          .openPopup()
          .on('popupclose', () => e.target._map.removeLayer(geocodeMarker))
        if (this._controlGeocoder && !this._controlGeocoder.options.collapsed) this._controlGeocoder._collapse()
      })

    window.addEventListener('keydown', e => {
      if (e.key == 'f' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        if (this._controlGeocoder && this._controlGeocoder.options.collapsed) this._controlGeocoder._expand()
      }
    })
  },
  _keydown: function(e) {
    e.stopPropagation()
  },
  addTo: function(map) {
    if (this._controlGeocoder) this._controlGeocoder.addTo(map)
    if (this._controlGeocoder) $(this._controlGeocoder.getContainer())
      .find('input')
      .on('keydown', this._keydown)
  },
  remove: function() {
    if (this._controlGeocoder) $(this._controlGeocoder.getContainer())
      .find('input')
      .off('keydown', this._keydown)
    if (this._controlGeocoder) this._controlGeocoder.remove()
  },
})

L.studioGeocode = options => new L.StudioGeocode(options)
