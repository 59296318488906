import {dump as jsyamlDump} from 'js-yaml'
import * as React from 'react'

import {ItemKV, ItemPre, ItemSection, Section} from '../../plugins/studioInfo/common/section.js'
import {chained} from '../../tools/common.js'

export class SectionMetadata extends React.Component {
  render() {
    return <Section title="Metadata" usePages={true} {...this.props}>
      <ItemSection title="Task" isFirst={true}/>
      <ItemPre>{jsyamlDump(chained(this.props, 'data.data.metadata.task'))}</ItemPre>
      <ItemSection title="Configuration / Files"/>
      {Object.entries(chained(this.props, 'data.data.metadata.configuration.files', {})).map(([k, v]) => <ItemKV  key={k} k={k} v={v} text={true}/>)}
      <ItemSection title="Bounding Box"/>
      {Object.entries(chained(this.props, 'data.data.metadata.boundingBox', {})).map(([k, v]) => <ItemKV key={k} k={k} v={v}/>)}
      <ItemSection title="Results"/>
      {Object.entries(chained(this.props, 'data.data.metadata.results', {})).map(([k, v]) => <ItemKV key={k} k={k} v={v}/>)}
      <ItemSection title="Running Times"/>
      {Object.entries(chained(this.props, 'data.data.metadata.runningTime', {}))
        .filter(([k, v]) => k.endsWith('Human'))
        .map(([k, v]) => <ItemKV key={k} k={k.replace('Human', '')} v={v}/>)}
    </Section>
  }
}
