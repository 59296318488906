import * as React from 'react'
import ReactDOM from 'react-dom'

const styles = {
  plugin: {
    position: 'relative',
    backgroundColor: '#fff',
    minHeight: 30,
    width: 270,
    margin: 0,
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: 4,
    backgroundClip: 'padding-box',
    cursor: 'default',  
  },
}

export const render = element => {
  const container = L.DomUtil.create('div', '')
  ReactDOM.render(element, container)
  return container
}

export const Plugin = props => <div
  className="leaflet-control"
  onClick={L.DomEvent.stopPropagation}
  onDoubleClick={L.DomEvent.stopPropagation}
  onWheel={L.DomEvent.stopPropagation}
  onMouseOver={e => {
    props.datasetManager.enableUpdateInfo(false)
    if (props.map) props.map.dragging.disable()
  }}
  onMouseOut={e => {
    props.datasetManager.enableUpdateInfo(true)
    if (props.map) props.map.dragging.enable()
  }}
  style={{
    ...styles.plugin,
    ...props.style,
  }}
>{props.children}</div>
