import {chained} from '../tools/common.js'
import 'Leaflet.SmoothWheelZoom/SmoothWheelZoom.js'

export const mapInit = options => {
  options = {
    eventPool: null,
    ...options,
  }
  // map
  const map = L.map('map', {
    attributionControl: false,
    minZoom: 0,
    maxZoom: 20,
    scrollWheelZoom: false,
    smoothWheelZoom: true,
    smoothSensitivity: 1,
    tap: false,
  }).setView([52.22, 6.90], 4)
  $('#map').on('keydown', e => {
    // stop key '6' from being propagated
    if (e.keyCode == '54') L.DomEvent.stopPropagation(e)
  })
  // L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png', {
  //   minZoom: 0,
  //   maxZoom: 20,
  //   opacity: .65,
  // }).addTo(map)
  L.mapboxGL({
    style: 'https://maps.mocnik-science.net/styles/osm-silence/style.json',
    minZoom: 0,
    maxZoom: 20,
  }).addTo(map)
  map.on('zoomend', () => options.eventPool.fire('mapParameterChange', {zoom: map.getZoom()}))
  map.on('moveend', () => options.eventPool.fire('mapParameterChange', {center: map.getCenter()}))
  // url
  let parameter = {
    tile: '',
    zoom: map.getZoom(),
    center: map.getCenter(),
  }
  options.eventPool.on('mapParameterChange', p => {
    parameter = {
      ...parameter,
      ...p,
    }
    window.history.pushState(parameter, '', '#{tile}={lon},{lat},{zoom}z'
      .replace('{tile}', parameter.tile)
      .replace('{lon}', parameter.center.lng)
      .replace('{lat}', parameter.center.lat)
      .replace('{zoom}', parameter.zoom))
  })
  return map
}

export const mapUpdateURL = (eventPool, map, filename) => eventPool.fire('mapParameterChange', {
  tile: (filename.startsWith('/tiles/') && filename.endsWith('/index.json')) ? filename.slice('/tiles/'.length, -'/index.json'.length) : null,
})

export const mapByURL = (eventPool, map) => {
  if (!window.location.hash) return
  const m = window.location.hash.match(/^#(.*)=(-?[0-9\.]+),(-?[0-9\.]+),([0-9\.]+)z$/)
  if (m[1] && m[1].length) eventPool.fire('loadDataByURL', '/tiles/' + m[1] + '/index.json', [m[3], m[2]], m[4])
}

export const mapFly = (map, dataset) => {
  const bb = chained(dataset, 'data.metadata.boundingBox')
  if (bb === undefined) return
  const bb2 = L.latLngBounds([[bb.minLat, bb.minLon], [bb.maxLat, bb.maxLon]])
  const minZoom = chained(dataset, 'aggregateByGrid') ? 9 : chained(dataset, 'data.metadata.tiles.availableZoom', false) && dataset.data.metadata.tiles.availableZoom.length >= 0 ? dataset.data.metadata.tiles.availableZoom[dataset.data.metadata.tiles.availableZoom.length - 1] + 3 : 7
  if (!bb2.contains(map.getBounds().pad(-.2))) map.setView(bb2.getCenter(), minZoom)
  else if (map.getZoom() < minZoom) map.setZoom(minZoom)
}
