export const bodyInit = options => {
  options = {
    onDragover: () => {},
    ...options,
  }
  $('body')
    .on('dragover', false)
    .on('drop', e => {
      e.preventDefault()
      e.stopPropagation()
      options.onDragover(e.originalEvent.dataTransfer.files[0])
    })
}
