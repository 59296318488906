import * as React from 'react'

import {DatasetManager} from '../format/datasetManager.js'
import {Plugin, render} from '../plugins/common/plugin.js'
import {SectionAbout} from '../plugins/studioInfo/sectionAbout.js'
import {SectionDatasets} from '../plugins/studioInfo/sectionDatasets.js'
import {SectionFilter} from '../plugins/studioInfo/sectionFilter.js'
import {SectionInfo} from '../plugins/studioInfo/sectionInfo.js'
import {SectionLegend} from '../plugins/studioInfo/sectionLegend.js'
import {SectionMetadata} from '../plugins/studioInfo/sectionMetadata.js'

const styles = {
  osmStudio: {
    textAlign: 'center',
    padding: '6px 8px 4px',
    fontFamily: '"Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 700,
    fontSize: 20,
    color: '#333',
  },
  osmStudioStudio: {
    fontFamily: '"Leckerli One", sans-serif',
    fontSize: 24,
    position: 'relative',
    bottom: '-0.06em',
    color: '#ed5565',
  },
}

class StudioInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      section: 'Datasets',
      page: 0,
      ...DatasetManager.initialState(),
    }
    const t = this
    // receive state changes from the dataset manager
    t.props.datasetManager.on('stateChange', s => t.setState(s))
    // listen to keyboard
    document.addEventListener('keydown', e => {
      try {
        const page = parseInt(e.key) - 1
        if (0 <= page && page < this.state.datasetsLoaded.length) t.setState({page})
      } catch {}
    })
  }
  render() {
    const sectionProps = {
      datasetManager: this.props.datasetManager,
      setState: s => this.setState(s),
      config: this.props.config,
      section: this.state.section,
      page: this.state.page,
      datasetsLoaded: this.state.datasetsLoaded,
      data: this.state.datasetsLoaded[this.state.page],
      event: this.state.event,
      eventPrevious: this.state.eventPrevious,
      aKey: this.state.aKey,
      aspectColor: this.state.aspectColor,
      aspectSize: this.state.aspectSize,
      cellColorOpacity: this.state.cellColorOpacity,
      showGridContour: this.state.showGridContour,
      showGridCentroid: this.state.showGridCentroid,
    }
    return <Plugin datasetManager={this.props.datasetManager} map={this.props.map}>
      <div style={styles.osmStudio}>OpenStreetMap <span style={styles.osmStudioStudio}>Studio</span></div>
      <SectionInfo {...sectionProps}/>
      <SectionLegend {...sectionProps}/>
      <SectionFilter {...sectionProps}/>
      <SectionMetadata {...sectionProps}/>
      <SectionDatasets {...sectionProps}/>
      <SectionAbout {...sectionProps}/>
    </Plugin>
  }
}

L.StudioInfo = L.Control.extend({
  options: {
    datasetManager: null,
    position: 'topright',
  },
  initialize: function(options) {
    L.Util.setOptions(this, options)
  },
  onAdd: function(map) {
    return render(<StudioInfo config={config} map={map} {...this.options}/>)
  },
  onRemove: function(map) {},
})
L.studioInfo = options => new L.StudioInfo(options)
