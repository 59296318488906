import {default as wellknown} from 'wellknown'

export class GeoJSONUtils {
  static dataEnrichGeometries(d, simplify) {
    if (d.geometryBefore && d.geometryBeforeGeoJSON === undefined) {
      d.geometryBeforeGeoJSON = GeoJSONUtils.simplify(wellknown.parse(d.geometryBefore), 1, 20, simplify)
      delete d.geometryBefore
    }
    if (d.geometryAfter && d.geometryAfterGeoJSON === undefined) {
      d.geometryAfterGeoJSON = GeoJSONUtils.simplify(wellknown.parse(d.geometryAfter), 1, 20, simplify)
      delete d.geometryAfter
    }
  }  
  static simplify(geoJSON, tolerance, simplifyMinimumPoints, simplify) {
    if (geoJSON.type == 'Point' || geoJSON.type == 'MultiPoint') return geoJSON
    else if (geoJSON.type == 'LineString' && geoJSON.coordinates.length >= simplifyMinimumPoints) geoJSON.coordinates = GeoJSONUtils._simplify(geoJSON.coordinates, tolerance, simplify)
    else if (geoJSON.type == 'MultiLineString') geoJSON.coordinates = geoJSON.coordinates.map(cs => cs.length >= simplifyMinimumPoints ? GeoJSONUtils._simplify(cs, tolerance, simplify) : cs)
    else if (geoJSON.type == 'Polygon' && geoJSON.coordinates.length > simplifyMinimumPoints) geoJSON.coordinates = geoJSON.coordinates.map(cs => GeoJSONUtils._simplifyPolygon(cs, tolerance, simplify))
    else if (geoJSON.type == 'MultiPolygon') geoJSON.coordinates = geoJSON.coordinates.map(cs1 => cs1.map(cs2 => cs2.length > simplifyMinimumPoints ? GeoJSONUtils._simplifyPolygon(cs2, tolerance, simplify) : cs2))
    return geoJSON
  }
  static _simplifyPolygon(points, tolerance, simplify) {
    return points.length <= 5 ? points : [...GeoJSONUtils._simplify(points.slice(0, Math.floor(points.length / 2)), tolerance, simplify), ...GeoJSONUtils._simplify(points.slice(Math.floor(points.length / 2)), tolerance, simplify)]
  }
  static _simplify(points, tolerance, simplify) {
    return points.length <= 3 ? points : simplify(points.map(p => ({x: p[0], y: p[1]})), tolerance).map(p => [p.x, p.y])
  }
}
