import * as React from 'react'

const styles = {
  bar: {
    display: 'flex',
    padding: '2px 8px',
    borderTop: '2px solid #bbb',
    backgroundColor: '#e3e3e3',
    userSelect: 'none',
  },
  barTitle: {
    flexGrow: 1,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  barPagesPageLink: {
    paddingLeft: 6,
    cursor: 'pointer',
    color: '#888',
    fontWeight: 700,
  },
  barPagesPageLinkActive: {
    color: '#000',
  },
  content: {
    overflowY: 'scroll',
    maxHeight: 700,
    userSelect: 'none',
  },
  contentInner: {
    padding: '6px 8px',
  },
}

export class Section extends React.Component {
  constructor(props) {
    super(props)
    this._handleTitleClick = this._handleTitleClick.bind(this)
    this._setContentContainer = this._setContentContainer.bind(this)
    this._openCloseContent = this._openCloseContent.bind(this)
  }
  _handleTitleClick() {
    this.props.setState(state => ({section: state.section != this.props.title ? this.props.title : null}))
  }
  _handlePageClick(e, n) {
    this.props.setState({page: n})
    e.stopPropagation()
  }
  _setContentContainer(contentContainer) {
    this._contentElement = contentContainer
  }
  _openCloseContent() {
    if (this.props.section == this.props.title) $(this._contentElement).slideDown('fast')
    else $(this._contentElement).slideUp('fast')
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.section != prevProps.section) this._openCloseContent()
  }
  componentDidMount() {
    this._openCloseContent()
  }
  render() {
    return <div>
      <div style={styles.bar} onClick={this._handleTitleClick}>
        <div style={styles.barTitle}>{this.props.title}</div>
        <div>
          {(this.props.usePages && this.props.datasetsLoaded.length > 1 ? this.props.datasetsLoaded : []).map((dl, n) => <span
            key={n}
            style={{
              ...styles.barPagesPageLink,
              ...(this.props.page == n ? styles.barPagesPageLinkActive : null),
            }}
            onClick={e => this._handlePageClick(e, n)}
          >{n + 1}</span>)}
        </div>
      </div>
      <div style={styles.content} ref={this._setContentContainer}>
        <div style={styles.contentInner}>
          {(this.props.usePages || this.props.requireData) && !this.props.data ? 'no dataset loaded' : this.props.children}
        </div>
      </div>
    </div>
  }
}

export const Header = props => <div style={{
  textAlign: 'center',
  marginBottom: 3,
  fontWeight: 700,
}}>{props.children}</div>

export const Hr = props => <hr style={{
  border: 0,
  borderBottom: '1px solid #999',
}}/>

export const ItemSection = props => <div style={{
  fontWeight: 700,
  paddingTop: 8,
  ...(props.isFirst ? {paddingTop: 0} : null),
}}>{props.title}</div>

export const ItemPre = props => <pre style={{
  display: 'flex',
  margin: 0,
  lineHeight: 'initial',
  userSelect: 'text',
}}>{props.children}</pre>

export const ItemKV = props => <div style={{
  display: 'flex',
  ...props.style,
}}>
  <div
    onClick={props.onClick}
    style={{
      whiteSpace: 'nowrap',
    }}
  >{props.k}</div><div style={{
    flexGrow: 1,
    textAlign: 'right',
    paddingLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'text',
    ...(props.text ? {direction: 'rtl'} : null),
  }}>{props.v === null || props.v === undefined ? '—' : props.v}</div>
</div>

export const A = props => <a href={props.href} target="_blank" style={{
  textDecoration: 'none',
  color: '#ed5565'
}}>{props.children}</a>

export const Ul = props => <ul style={{
  padding: 0,
  margin: '3px 0',
}}>{props.children}</ul>

export const Li = props => <li style={{
  paddingLeft: 9,
  listStyleType: 'none',
  background: 'transparent',
  backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'none\' stroke=\'black\' stroke-width=\'1\' height=\'18\' width=\'18\' viewBox=\'0 0 18 18\' xmlns=\'http://www.w3.org/2000/svg\'><circle cx=\'6\' cy=\'9\' r=\'2\'/></svg>")',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: -3,
  backgroundPositionY: 0,
}}>{props.children}</li>
