export const chained = (array, keys, defaultValue=undefined) => {
  const ks = keys.split('.')
  if (ks.length == 0) return array
  for (const k of ks) {
    if (array === undefined || array === null) return defaultValue
    array = array[k]
  }
  if (array === undefined) return defaultValue
  return array
}

export const listRemove = (xs, n) => n < 0 || n > xs.length - 1 ? xs : [...xs.slice(0, n), ...xs.slice(n + 1)]
export const listMoveUp = (xs, n) => n < 1 || n > xs.length - 1 ? xs : [...xs.slice(0, n - 1), xs[n], xs[n - 1], ...xs.slice(n + 1)]
export const listMoveDown = (xs, n) => listMoveUp(xs, n + 1)
export const listReplace = (xs, n, d) => n < 0 || n > xs.length - 1 ? xs : [...xs.slice(0, n), d, ...xs.slice(n + 1)]
