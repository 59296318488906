import './index.css'
import {bodyInit} from './components/body.js'
import {mapByURL, mapInit, mapUpdateURL} from './components/map.js'
import {checkFileFormat} from './format/check.js'
import {DatasetManager} from './format/datasetManager'
import './plugins/studioGeocode.js'
import './plugins/studioGeometry.js'
import './plugins/studioGrid.js'
import './plugins/studioInfo.js'
import './plugins/studioTimeline.js'
import './plugins/studioWarning.js'
import {EventPool} from './tools/eventPool'
import {loadFileJSON} from './tools/loadFileJSON.js'

$(document).ready(function() {
  // events
  const eventPool = new EventPool()

  // init map and plugins
  const map = mapInit({eventPool})

  // init dataset manager
  const datasetManager = new DatasetManager({map})

  // init plugins
  datasetManager.setStudioGrid(L.studioGrid({datasetManager}).addTo(map))
  datasetManager.setStudioGeometry(L.studioGeometry({datasetManager}).addTo(map))
  L.studioInfo({datasetManager}).addTo(map)
  L.studioTimeline({datasetManager}).addTo(map)
  L.studioGeocode({datasetManager}).addTo(map)
  L.studioWarning({datasetManager}).addTo(map)

  // init map by url
  mapByURL(eventPool, map)

  // init body
  bodyInit({
    onDragover: file => loadFileJSON(file, data => {
      checkFileFormat(data)
      const filename = file.name
      mapUpdateURL(eventPool, map, filename)
      datasetManager.addDataset({
        title: filename,
        filename: filename,
        data: data,
      })
    }),
  })
})
