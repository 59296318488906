import 'Leaflet.Deflate'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import {prepareFilter} from '../plugins/common/data.js'
import {chained} from '../tools/common.js'
import {download} from '../tools/download.js'
import {GeoJSONUtils} from '../../shared/javascript/geoJSONUtils.js'

const radians = degrees => degrees * Math.PI / 180

const latLonToTileID = (lat, lon, zoom) => {
  const n = 2**zoom
  const x = Math.floor(n * (lon + 180) / 360)
  const y = Math.floor(n / 2 * (1 - Math.log(Math.tan(radians(lat)) + 1 / Math.cos(radians(lat))) / Math.PI))
  return [x, y]
}

L.StudioGeometry = L.Layer.extend({
  options: {
    datasetManager: null,
    bboxDataPad: 1.4,
    relativeMaxCache: 2,
    circleMarkerRadius: 4,
  },
  initialize: function(options) {
    this._map = null
    this._urlsLoaded = {}
    this._dataLoaded = {}
    this._filenames = {}
    this._filterByValue = {}
    this._filterByText = {}
    this._data = {}
    this._dataDictionary = {}
    this._geoJsonLayers = {}
    this._tileZoom = {}

    // init options
    L.Util.setOptions(this, options)

    const t = this

    // init layers
    const layerMarkerClusterGroup = L.markerClusterGroup({
      showCoverageOnHover: true,
      chunkedLoading: true,
    })
      .on('mouseover', e => t.options.datasetManager.elementEvent('geometry', prevE => prevE && prevE.type == 'click' && prevE.target == e.sourceTarget ? undefined : {
        type: 'mouseOver',
        datasetID: e.sourceTarget.feature.properties.datasetID,
        data: e.sourceTarget.feature.properties,
        target: e.sourceTarget,
        layerType: 'geometry',
        aggregatedByGrid: false,
      }))
      .on('click', e => t.options.datasetManager.elementEvent('geometry', prevE => prevE && prevE.type == 'click' && prevE.target == e.sourceTarget ? null : {
        type: 'click',
        datasetID: e.sourceTarget.feature.properties.datasetID,
        data: e.sourceTarget.feature.properties,
        target: e.sourceTarget,
        layerType: 'geometry',
        aggregatedByGrid: false,
      }))
      .on('mouseout', e => t.options.datasetManager.elementEvent('geometry', prevE => prevE && prevE.type == 'click' ? undefined : null))
    t._layer = L.deflate({
      minSize: t.options.circleMarkerRadius,
      markerType: L.circleMarker,
      markerOptions: {radius: t.options.circleMarkerRadius},
      markerLayer: layerMarkerClusterGroup,
    })
  },
  loadData: function(id, data, filename, filterByValue, filterByText) {
    const t = this
    t._resetLayer(id)
    t._urlsLoaded[id] = []
    t._dataLoaded[id] = false
    t._filenames[id] = filename
    t._filterByValue[id] = filterByValue
    t._filterByText[id] = filterByText
    t._data[id] = data
    t._dataDictionary[id] = null
    t._loadData(id)
    const availableZoom = chained(data, 'metadata.tiles.availableZoom', [])
    t._tileZoom[id] = availableZoom.length > 0 ? availableZoom[0] : null
    t._handleZoom()
    return this
  },
  removeData: function(id) {
    const t = this
    delete t._urlsLoaded[id]
    delete t._dataLoaded[id]
    delete t._filenames[id]
    delete t._filterByValue[id]
    delete t._filterByText[id]
    delete t._data[id]
    delete t._dataDictionary[id]
    for (const l of t._geoJsonLayers[id]) t._layer.removeLayer(l)
    delete t._geoJsonLayers[id]
    delete t._tileZoom[id]
    t._handleZoom()
    return this
  },
  updateFilter(id, filterByValue, filterByText) {
    const t = this
    t._filterByValue[id] = filterByValue
    t._filterByText[id] = filterByText
    if (t._dataDictionary[id]) t._visualizeDataDictionary(id, t._dataDictionary[id], true)
    else if (t._data[id]) t._visualizeData(id, t._data[id], true)
    return this
  },
  _resetLayer: function(id) {
    const t = this
    if (t._geoJsonLayers[id]) for (const l of t._geoJsonLayers[id]) t._layer.removeLayer(l)
    t._dataLoaded[id] = false
    t._geoJsonLayers[id] = []
  },
  _loadData: function(id, callback=null) {
    const t = this
    const ids = id !== undefined ? [id] : Object.keys(t._data)
    for (id of ids) {
      const data = t._data[id]
      if (data.url) {
        // load the data
        t._updateDataByURL(id, (dataDictionary, reload) => {
          t._dataDictionary[id] = dataDictionary
          // visualize the data
          t._visualizeDataDictionary(id, dataDictionary, reload)
          // callback
          if (callback) callback(t._dataDictionaryToData(data, dataDictionary))
        })
      } else if (data.data) {
        // visualize the data
        if (!t._dataLoaded[id]) {
          t._visualizeData(id, data, true)
          t._dataLoaded[id] = true
        }
        // callback
        if (callback) callback(data)
      }  
    }
  },
  exportAsGeoJSON: function(d) {
    const t = this
    const filename = chained(d, 'filename') ? chained(d, 'filename').replace(/\.json$/, '.geojson') : chained(d, 'data.metadata.tiles.createdFromFile') ? chained(d, 'data.metadata.tiles.createdFromFile').replace(/\.json$/, '.geojson') : 'osm-studio.geojson'
    if (chained(d, 'data.url')) t._updateDataByURL(d.id, (dataDictionary, reload) => download(filename, JSON.stringify(t._dataToGeoJson(t._dataDictionaryToData(d.data, dataDictionary)))), true)
    else if (chained(d, 'data.data')) download(filename, JSON.stringify(t._dataToGeoJson(chained(d, 'data'))))
  },
  _dataToGeoJson: function(data) {
    return {
      type: 'FeatureCollection',
      features: (data && data.data ? data.data : []).map(d => {
        GeoJSONUtils.dataEnrichGeometries(d, L.LineUtil.simplify)
        return d.geometryAfterGeoJSON
      }),
    }
  },
  _handleZoom() {
    const t = this
    const availableZoom = Object.values(t._tileZoom).filter(tz => tz !== undefined && tz !== null)
    const tileZoom = availableZoom.length > 0 ? Math.max(...availableZoom) : null
    if (!t._map || tileZoom === null || tileZoom < 1) return
    if (t._map.getZoom() < tileZoom + 1.5) {
      if (t._map.hasLayer(t._layer)) t._layer.removeFrom(t._map)
      t.options.datasetManager.hideLayer('geomtry', true)
    } else {
      if (!t._map.hasLayer(t._layer)) t._layer.addTo(t._map)
      t.options.datasetManager.hideLayer('geometry', false)
    }
  },
  onAdd: function(map) {
    this._map = map
    this._map.addLayer(this._layer)
    this._loadData()
    
    // events
    this._map.on('viewreset', this._onEvent, this)
    this._map.on('zoom', () => this._handleZoom())
    this._map.on('zoomend', this._onEvent, this)
    this._map.on('moveend', this._onEvent, this)
  },
  onRemove: function(map) {
    // events
    this._map.off('viewreset', this._onEvent, this)
    this._map.off('zoom', () => this._handleZoom())
    this._map.off('zoomend', this._onEvent, this)
    this._map.off('moveend', this._onEvent, this)

    this._map.removeLayer(this._layer)
    this._map = null
  },
  _onEvent: function(e) {
    this._loadData()
  },
  _dataDictionaryToData(data, dataDictionary) {
    return {
      data: Object.values(dataDictionary).filter(d => d && d.data).flatMap(d => d.data),
      ...data,
    }
  },
  _relevantXYs: function(id) {
    const t = this
    if (chained(t._data[id], 'metadata.tiles.availableZoom', []).length == 0) return []
    const bbox = this._map.getBounds().pad(this.options.bboxDataPad - 1)
    const zoom = t._data[id].metadata.tiles.availableZoom[t._data[id].metadata.tiles.availableZoom.length - 1]
    const [xMin, yMin] = latLonToTileID(Math.min(90, bbox.getNorth()), Math.max(-180, bbox.getWest()), zoom)
    const [xMax, yMax] = latLonToTileID(Math.max(-90, bbox.getSouth()), Math.min(180, bbox.getEast()), zoom)
    const xy = []
    for (let x = xMin; x <= xMax; x++) for (let y = yMin; y <= yMax; y++) xy.push([x, y])
    return xy
  },
  _updateDataByURL: function(id, callback, forceAll=false) {
    const t = this
    if (!t._data[id].url) return
    const url = t._data[id].url.replace('{z}', chained(t._data[id], 'metadata.tiles.availableZoom', []).length > 0 ? t._data[id].metadata.tiles.availableZoom[t._data[id].metadata.tiles.availableZoom.length - 1] : 7)
    const xys = t._relevantXYs(id)
    const reload = t._urlsLoaded[id].length > t.options.relativeMaxCache * xys.length
    if (reload && !forceAll) t._urlsLoaded[id] = []
    const urls = xys
      .map(([x, y]) => url.replace('{x}', x).replace('{y}', y))
      .filter(url => forceAll || !t._urlsLoaded[id].includes(url))
    if (!forceAll) t._urlsLoaded[id] = t._urlsLoaded[id].concat(urls)
    t._download(urls, data => callback(data, reload))
  },
  _download: function(urls, callback) {
    const ds = {}
    // prepare the final data
    const useData = (url, data) => {
      ds[url] = data
      if (Object.keys(ds).length == urls.length) {
        try {
          callback(ds)
        } catch (e) {
          console.error(e)
        }
      }
    }
    // start downloads
    for (const url of urls) d3.json(url).then(data => useData(url, data)).catch(e => useData(url, null))
  },
  _visualizeData: function(id, data, reload) {
    const t = this
    if (reload) t._resetLayer(id)
    if (data && data.data) {
      // prepare filter
      const filterAway = prepareFilter(t._filterByValue[id], t._filterByText[id])
      // produce geoJSON
      const geoJSON = {
        type: 'FeatureCollection',
        features: [],
      }
      for (const d of data.data) {
        if (filterAway(d)) continue
        GeoJSONUtils.dataEnrichGeometries(d, L.LineUtil.simplify)
        geoJSON.features.push({
          type: 'Feature',
          properties: {
            ...d,
            datasetID: id,
          },
          geometry: d.geometryAfterGeoJSON,
        })
      }
      // add layer
      if (geoJSON.features.length) {
        const l = L.geoJson(geoJSON, {
          pointToLayer: (geoJsonPoint, latlng) => L.circleMarker(latlng, {radius: t.options.circleMarkerRadius}),
        }).addTo(t._layer)
        t._geoJsonLayers[id].push(l)
      }
    }
  },
  _visualizeDataDictionary: function(id, dataDictionary, reload) {
    const t = this
    if (reload) t._resetLayer(id)
    for (const [url, data] of Object.entries(dataDictionary)) t._visualizeData(id, data, false)
  },
})
L.studioGeometry = options => new L.StudioGeometry(options)
