import * as React from 'react'

export class Slider extends React.Component {
  constructor(props) {
    super(props)
    const t = this
    t._setSlider = t._setSlider.bind(t)
  }
  _setSlider(slider) {
    this._slider = slider
  }
  _updateSlider() {
    const t = this
    if (t._sliderObject) t._sliderObject.update({
      ...(t.props.values ? {values: t.props.values} : null),
      ...(t.props.range ? {min: t.props.range[0], max: t.props.range[1]} : null),
      step: t.props.step ? t.props.step : 1,
      from: t.props.from,
      to: t.props.to,
      from_max: t.props.fromMax,
      to_min: t.props.toMin,
      block: t.props.disabled,
    })
    if (t._slider) {
      if (t.props.style) $(t._slider).prev().css(t.props.style)
      if (t.props.styleHandle) $(t._slider).prev().find('.irs-handle > i:first-child').css(t.props.styleHandle)
      if (t.props.styleBar) $(t._slider).prev().find('.irs-bar').css(t.props.styleBar)
      if (t.props.styleLine) $(t._slider).prev().find('.irs > .irs-line').css(t.props.styleLine)
    }
  }
  componentDidUpdate(prevProps, prevStats, snapshot) {
    const t = this
    if (['disabled', 'fromMax', 'toMin'].some(k => prevProps[k] != this.props[k])) t._updateSlider()
    else if (t._dragging && ['values'].some(k => prevProps[k] != this.props[k])) t._updateSlider()
    else if (!t._dragging && ['values', 'from', 'to'].some(k => prevProps[k] != this.props[k])) t._updateSlider()
  }
  _mapValue(v) {
    const t = this
    return t.props.values !== undefined ? t.props.values[v] : v
  }
  _fromTo() {
    const t = this
    const from = $(t._slider).data('from')
    const to = $(t._slider).data('to')
    return [t._mapValue(from), t._mapValue(to)]
  }
  componentDidMount() {
    const t = this
    $(t._slider).ionRangeSlider({
      ...t.props.options,
      block: t.props.disabled,
      onStart: () => t._dragging = true,
      onChange: () => {
        t._dragging = true
        if (t.props.onChange) t.props.onChange(...t._fromTo())
      },
      onFinish: () => {
        t._dragging = false
        if (t.props.onChange) t.props.onChange(...t._fromTo())
      },
    })
    t._sliderObject = $(t._slider).data('ionRangeSlider')
    t._updateSlider()
  }
  componentWillUnmount() {
    const t = this
    if (t._sliderObject) t._sliderObject.destroy()
  }
  render() {
    return <input type="text" ref={this._setSlider}/>
  }
}
