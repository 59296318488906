import {chained} from '../tools/common'

const colors = [
  {
    name: 'red',
    interpolate: d3.interpolateReds,
    color: '#ed5565',
  },
  {
    name: 'blue',
    interpolate: d3.interpolateBlues,
    color: '#5583ed',
  },
  {
    name: 'green',
    interpolate: d3.interpolateGreens,
    color: '#7fc87a',
  },
  {
    name: 'yellow',
    interpolate: d3.interpolateRgbBasis(['#fff', '#d4a114']),
    color: '#ebbc30',
  },
  {
    name: 'purple',
    interpolate: d3.interpolatePurples,
    color: '#9c55ed',
  },
  {
    name: 'turquoise',
    interpolate: d3.interpolateRgbBasis(['#fff', '#074045']),
    color: '#129aa6',
  },
]

const colorPairs = [
  {
    name: 'blue–red', 
    interpolate: t => d3.interpolateRdYlBu(1 - t),
  },
  {
    name: 'pruple-green', 
    interpolate: d3.interpolatePRGn,
  },
  {
    name: 'red–green', 
    interpolate: t => d3.interpolateRdYlGn(1 - t),
  },
  {
    name: 'blue–red', 
    interpolate: t => d3.interpolateRdYlBu(1 - t),
  },
  {
    name: 'pruple-green', 
    interpolate: d3.interpolatePRGn,
  },
  {
    name: 'red–green', 
    interpolate: t => d3.interpolateRdYlGn(1 - t),
  },
]

export const colorScheme = k => chained(colorSchemes, k)

export const colorSchemes = {
  sequential: colors.map((v, i) => ({
    id: `sequential.${i}`,
    name: `sequential (${v.name})`,
    scheme: (min, max) => d3.scaleLinear().domain([min, max]).interpolate(() => v.interpolate),
  })),
  diverging: colorPairs.map((v, i) => ({
    id: `diverging.${i}`,
    name: `diverging (${v.name})`,
    scheme: (min, max) => d3.scaleDiverging().domain([min, 0, max]).interpolator(v.interpolate),
  })),
  constant: colors.map((v, i) => ({
    id: `constant.${i}`,
    name: `constant (${v.name})`,
    scheme: (min, max) => value => v.color,
  })),
}
