import ArrowDown from 'bootstrap-icons/icons/arrow-down.svg'
import ArrowUp from 'bootstrap-icons/icons/arrow-up.svg'
import CloudDownload from 'bootstrap-icons/icons/cloud-download.svg'
import PlusCircle from 'bootstrap-icons/icons/plus-circle.svg'
import Trash from 'bootstrap-icons/icons/trash.svg'
import * as React from 'react'

import SquareGeometry from '../../images/square-geometry.svg'
import SquareGrid from '../../images/square-grid.svg'
import SquareTime from '../../images/square-time.svg'
import {Header, Hr, Section} from '../../plugins/studioInfo/common/section.js'
import {chained} from '../../tools/common.js'

const styles = {
  dataset: {
    display: 'flex',
    marginTop: 5,
  },
  datasetIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  datasetIconOuter: {
    width: 16,
    height: 16,
    flexShrink: 0,
    flexGrow: 0,
  },
  datasetIconOuterBefore: {
    paddingRight: 4,
  },
  datasetIconOuterAfter: {
    paddingLeft: 4,
  },
  datasetIconOuterInactive: {
    color: '#aaa',
  },
  datasetTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  datasetNoDataset: {
    margin: '5px 0',
    textAlign: 'center',
    fontStyle: 'italic',
  },
}

const DatasetIcon = props => props.actions.map((a, i) => <div key={i} style={{
    ...styles.datasetIconOuter,
    ...(props.after ? styles.datasetIconOuterAfter : styles.datasetIconOuterBefore),
    ...a.style,
    ...(a.active === undefined || a.active ? null : styles.datasetIconOuterInactive),
  }}>{a.visible === undefined || a.visible ? <a.icon style={styles.datasetIcon} onClick={a.action}/> : null}</div>)

const Dataset = props => {
  props = {
    title: '',
    actions: [],
    actionsAfter: [],
    color: '#000',
    ...props,
  }
  return <div style={styles.dataset}>
    <DatasetIcon actions={props.actions} after={false}/>
    <div title={props.title} style={{
      ...styles.datasetTitle,
      color: props.color,
    }}>{props.title}</div>
    <DatasetIcon actions={props.actionsAfter} after={true}/>
  </div>
}

export class SectionDatasets extends React.Component {
  render() {
    const configDatasetsList = chained(this.props, 'config.datasets.list', [])
    return <Section title="Datasets" {...this.props}>
      <Header>Datasets loaded</Header>
      {this.props.datasetsLoaded.length ? null : <div style={styles.datasetNoDataset}>no dataset loaded</div>}
      {this.props.datasetsLoaded.map((d, n) => <Dataset
        key={d.id}
        title={d.title}
        actions={[
          {
            icon: SquareGrid,
            active: d.aggregateByGrid && !d.splitGridCellByTime,
            action: () => {
              this.props.datasetManager.changeAggregateByGrid(d, true)
              this.props.datasetManager.changeSplitGridCellByTime(d, false)
            },
          },
          ...(chained(d, 'data.metadata.format.aggregatedByGrid') ? [
            {
              icon: SquareTime,
              active: d.aggregateByGrid && d.splitGridCellByTime,
              visible: chained(d, 'data.metadata.format.intervals', false),
              action: () => {
                this.props.datasetManager.changeAggregateByGrid(d, true)
                this.props.datasetManager.changeSplitGridCellByTime(d, true)
              },
            },
          ] : [
            {
              icon: SquareGeometry,
              active: !d.aggregateByGrid,
              action: () => this.props.datasetManager.changeAggregateByGrid(d, false),
            },
          ]),
          {
            icon: ArrowDown,
            style: {marginRight: -5},
            visible: n < this.props.datasetsLoaded.length - 1,
            action: () => this.props.datasetManager.moveDatasetDown(d),
          },
          {
            icon: ArrowUp,
            visible: n > 0,
            action: () => this.props.datasetManager.moveDatasetUp(d),
          },
        ]}
        actionsAfter={[
          {
            icon: CloudDownload,
            action: () => this.props.datasetManager.exportAsGeoJSON(d),
          },
          {
            icon: Trash,
            action: () => this.props.datasetManager.removeDataset(d),
          },
        ]}
      />)}
      <Hr/>
      <Header>Datasets available</Header>
      {Object.entries(configDatasetsList).map(([title, url]) => {
        const available = this.props.datasetsLoaded.every(d => d.url != url)
        return <Dataset
          key={url}
          title={title}
          color={available ? undefined : '#999'}
          actions={[{
            icon: PlusCircle,
            style: {marginRight: 2},
            active: available,
            action: () => this.props.datasetManager.addDataset({
              title: title,
              filename: url,
              url: url,
            }),
          }]}
        />
      })}
    </Section>
  }
}
