import * as React from 'react'
import embed from 'vega-embed'

import {aspectFormat, aspectHide, aspectName} from '../../format/aspects.js'
import {ItemKV, ItemSection, Section} from '../../plugins/studioInfo/common/section.js'
import {chained} from '../../tools/common.js'

const styles = {
  itemAspectSelected: {
    fontWeight: 700,
  },
  itemDiagram: {
    display: 'flex',
    padding: '10px 14px 0 40px',
    margin: 0,
  },
  itemDiagramFirst: {
    paddingTop: 5,
  },
  diagram: {
    height: 76,
  },
  diagramLast: {
    height: 90,
  },
  note: {
    textAlign: 'center',
    fontSize: 11,
    fontStyle: 'italic',
    color: '#aaa',
  },
}

export class VegaLite extends React.Component {
  constructor(props) {
    super(props)
    this._setElement = this._setElement.bind(this)
  }
  _setElement(element) {
    this._element = element
  }
  _render(force=false) {
    if (force || !$(this._element).hasClass('vega-embed')) embed(this._element, this.props.spec, {actions: false})
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this._render(JSON.stringify(prevProps.spec) != JSON.stringify(this.props.spec))
  }
  componentDidMount() {
    this._render()
  }
  render() {
    return <div ref={this._setElement} onClick={this.props.onClick} style={this.props.style}/>
  }
}

export class SectionInfo extends React.Component {
  render() {
    const t = this
    const dataset = t.props.data
    const dataKeys = chained(dataset, '_dataKeys', []).filter(k => !aspectHide(k))
    let content
    let diagramSpec = null
    let vs = {}
    let event = null
    if (dataset) {
      event = t.props.event[dataset.aggregateByGrid ? 'grid' : 'geometry'] || t.props.eventPrevious[dataset.aggregateByGrid ? 'grid' : 'geometry']
      const hasCurrentEvent = t.props.event[dataset.aggregateByGrid ? 'grid' : 'geometry'] !== undefined && t.props.event[dataset.aggregateByGrid ? 'grid' : 'geometry'] !== null
      if (hasCurrentEvent && dataset.aggregateByGrid) {
        vs = event.data[this.props.page]
        if (chained(dataset, 'data.metadata.format.aggregatedByIntervals')) {
          const dataNotMapped = [
            ...(event.dataNotMapped[this.props.page] !== null ? [event.dataNotMapped[this.props.page]] : []),
            ...(event.dataNotMappedNeighbors ? event.dataNotMappedNeighbors.map(d => d[this.props.page]) : []),
          ]
          let values = dataNotMapped.flatMap((x, i) => x.aggregated === undefined || x.aggregated === null ? [] : Object.entries(x.aggregated).map(([aKey, v]) => ({
            aKey,
            ...v,
            _i: i,
            _color: i == 0 ? '#ed5565' : '#ddd',
          })))
          values.reverse()
          if (values.length == 0) values = chained(dataset, 'data.metadata.format.intervals', []).map(interval => ({aKey: interval}))
          const dataRange = k => {
            const c = chained(dataset, 'dataRange')
            return c && c[k] && c[k] ? chained(dataset, 'dataRange')[k].color : null
          }
          diagramSpec = (k, last) => ({
            $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
            height: 60,
            layer: [
              {
                data: {values},
                mark: {type: 'line', point: {size: 10}},
                encoding: {
                  x: {field: 'aKey', type: 'temporal', title: null},
                  y: {field: k, type: 'quantitative', title: null, scale: {domain: dataRange(k)}},
                  color: {field: '_color', type: 'nominal', scale: null},
                  detail: {field: '_i', type: 'nominal'}
                },
              },
              {
                data: {values: [{aKey: dataset.aKey}]},
                mark: {type: 'rule', color: '#000', size: 1},
                encoding: {x: {field: 'aKey', type: 'temporal', title: null}},
              },
            ],
            config: {
              autosize: 'none',
              padding: {top: 5, right: 5, bottom: last ? 20 : 5, left: 40},
            },
          })
        }
      } else if (hasCurrentEvent) {
        vs = event.data
        if (vs.geometryBeforeGeoJSON) vs.geometryBefore = vs.geometryBeforeGeoJSON.type
        if (vs.geometryAfterGeoJSON) vs.geometryAfter = vs.geometryAfterGeoJSON.type
      }
    }
    content = dataKeys.map((k, i) => [
      <ItemKV
        key={k}
        k={aspectName(k)}
        v={vs[k] === undefined || vs[k] === null ? '–' : aspectFormat(dataset, k, vs[k])}
        style={{
          ...(dataset.aspectColor == k ? styles.itemAspectSelected : null),
          ...(diagramSpec ? styles.itemDiagram : null),
          ...(diagramSpec && i == 0 ? styles.itemDiagramFirst : null),
        }}
        onClick={e => t.props.datasetManager.changeAspectColor(dataset, k)}
      />,
      ...(diagramSpec ? [
        <VegaLite
          key={`${k}-diagram`}
          spec={diagramSpec(k, i == dataKeys.length - 1)}
          style={{
            ...styles.diagram,
            ...(i == dataKeys.length - 1 ? styles.diagramLast : null),
          }}
          onClick={e => t.props.datasetManager.changeAspectColor(dataset, k)}
        />,
        i == dataKeys.length - 1 ? <div key="note" style={styles.note}>The data of neighbouring cells is depicted in grey.</div> : null,
      ] : []),
    ])
    return <Section title="Info" usePages={true} {...this.props}>
      {content}
      {event && event.cell && dataset && dataset.aggregateByGrid ? [
         <ItemSection key="cell" title="Cell"/>,
         <ItemKV key="id" k="ID" v={event.cell.id}/>,
         <ItemKV key="lat" k="Latitude" v={event.cell.lat.toFixed(6)}/>,
         <ItemKV key="lon" k="Longitude" v={event.cell.lon.toFixed(6)}/>,
       ] : null}
    </Section>
  }
}
