import {colorSchemes} from '../format/colorSchemes.js'
import {chained} from '../tools/common.js'

export const aspectName = key => {
  const n = aspectFor(key).name
  return n !== undefined ? n : key
}
export const aspectOptionsColor = key => aspectFor(key).optionsColor
export const aspectOptionsSize = key => aspectFor(key).optionsSize
export const aspectCellColorScale = (key, i, rangeColor) => aspectFor(key).cellColorScale(...rangeColor)[i]
export const aspectsMapRange = key => aspectFor(key).mapRange
export const aspectFormat = (d, key, value, ...as) => {
  const f = aspectFor(key).format
  const v = f === undefined ? value : f(value, ...as)
  const vm = chained(d, 'data.metadata.format.valueMeaning', {})[key]
  if (vm === undefined || vm === null) return v
  for (const [key, value] of Object.entries(vm)) if (v == key) return value
  return v
}
export const aspectHide = key => aspectFor(key).hide

export const aspectFor = key => aspects[key] !== undefined ? aspects[key] : aspects['default']

export const cellSizeScaleMono = (min, max) => value => Math.max(0, Math.min(1, (value - min) / (max - min)))

export const aspects = {
  // INDIVIDUAL
  changesetID: {
    name: 'Changeset ID',
  },
  timestamp: {
    name: 'Timestamp',
  },
  value: {
    name: 'Value',
  },
  elementID: {
    name: 'Element ID',
  },
  elementType: {
    name: 'Element Type',
  },
  version: {
    name: 'Version',
  },
  userID: {
    name: 'User ID',
  },
  contributionTypes: {
    name: 'Contribution types',
    format: v => v.join(', ')
  },
  geometryBefore: {
    name: 'Geometry before',
  },
  geometryAfter: {
    name: 'Geometry after',
  },
  userFamiliarity: {
    name: 'User familiarity',
    format: v => v.join(', ')
  },
  isea3hID: {
    name: 'Grid cell ID',
  },
  // GEOMETRY
  count: {
    name: 'Count',
    optionsColor: {
      cellColorMin: 0,
      cellColorMax: null,
    },
    cellColorScale: (min, max) => colorSchemes.sequential,
    optionsSize: {
      cellSizeMin: null,
      cellSizeMax: null,
      cellSizeScale: cellSizeScaleMono,
    },
    mapRange: (min, max) => [0, max],
  },
  // STATISTICS
  mean: {
    name: 'Mean',
    optionsColor: {
      cellColorMin: null,
      cellColorMax: null,
    },
    cellColorScale: (min, max) => colorSchemes.diverging,
    optionsSize: {
      cellSizeMin: null,
      cellSizeMax: null,
      cellSizeScale: cellSizeScaleMono,
    },
    mapRange: (min, max) => [-Math.max(max, -min), Math.max(max, -min)],
    format: v => parseFloat(v).toFixed(2),
  },
  numberOfResults: {
    name: 'Number of results',
    optionsColor: {
      cellColorMin: 0,
      cellColorMax: null,
    },
    cellColorScale: (min, max) => colorSchemes.sequential,
    optionsSize: {
      cellSizeMin: null,
      cellSizeMax: null,
      cellSizeScale: cellSizeScaleMono,
    },
    mapRange: (min, max) => [min, max],
    format: v => parseFloat(v).toFixed(0),
  },
  variance: {
    name: 'Variance',
    optionsColor: {
      cellColorMin: 0,
      cellColorMax: null,
    },
    cellColorScale: (min, max) => colorSchemes.sequential,
    optionsSize: {
      cellSizeMin: null,
      cellSizeMax: null,
      cellSizeScale: cellSizeScaleMono,
    },
    mapRange: (min, max) => [min, max],
    format: v => parseFloat(v).toFixed(2),
    hide: true,
  },
  standardDeviation: {
    name: 'Standard deviation',
    optionsColor: {
      cellColorMin: 0,
      cellColorMax: null,
    },
    cellColorScale: (min, max) => colorSchemes.sequential,
    optionsSize: {
      cellSizeMin: null,
      cellSizeMax: null,
      cellSizeScale: cellSizeScaleMono,
    },
    mapRange: (min, max) => [min, max],
    format: v => parseFloat(v).toFixed(2),
  },
  // NULL AND DEFAULT
  null: {
    optionsSize: {
      cellSizeKey: null,
      cellSizeMin: null,
      cellSizeMax: null,
      cellSizeScale: (min, max) => value => 1,
    },
    mapRange: (min, max) => [min, max],
  },
  default: {
    optionsColor: {
      cellColorMin: (min, max) => min >= 0 ? 0 : null,
      cellColorMax: null,
    },
    cellColorScale: (min, max) => min >= 0 ? colorSchemes.sequential : colorSchemes.diverging,
    optionsSize: {
      cellSizeMin: null,
      cellSizeMax: null,
      cellSizeScale: cellSizeScaleMono,
    },
    mapRange: (min, max) => min >= 0 ? [0, max] : [-Math.max(max, -min), Math.max(max, -min)],
    format: v => typeof v == 'number' ? parseFloat(v).toFixed(2) : v,
  },
}
