import * as React from 'react'

import {A, Hr, Li, Section, Ul} from '../../plugins/studioInfo/common/section.js'

export class SectionAbout extends React.Component {
  render() {
    return <Section title="About" {...this.props}>
      <div>This project uses the following software products:</div>
      <Ul>
        <Li><A href="https://github.com/GIScience/oshdb">OpenStreetMap History Database (OSHDB)</A></Li>
        <Li><A href="https://github.com/mocnik-science/geogrid.js">geogrid.js</A> and <A href="https://github.com/mocnik-science/geogrid">geogrid</A></Li>
        <Li><A href="http://leafletjs.com/">Leaflet</A></Li>
        <Li>Map tiles by <A href="https://maptiler.com/copyright">MapTiler</A></Li>
        <Li>Data by <A href="http://openstreetmap.org">OpenStreetMap</A></Li>
      </Ul>
      <div style={{marginTop: 6}}>Further dependencies are documented in the repository itself:</div>
      <div><A href="http://www.github.com/mocnik-science/osm-studio">github.com/mocnik-science/osm-studio</A></div>
      <Hr/>
      <div style={{
        marginBottom: 1,
        textAlign: 'center',
      }}>(c) by <A href="https://www.mocnik-science.net">Franz-Benjamin Mocnik</A>, 2020</div>
    </Section>
  }
}
