import * as React from 'react'

import {DatasetManager} from '../format/datasetManager.js'
import {Plugin, render} from '../plugins/common/plugin.js'
import {Slider} from '../plugins/common/slider.js'
import {chained} from '../tools/common.js'

const styles = {
  pluginOuter: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
    pointerEvents: 'none',
  },
  plugin: {
    minHeight: 60,
    width: 'min(100% - 60px, 400px)',
    marginLeft: 0,
    marginRight: 0,
    padding: '12px 30px 10px 30px',
    backgroundColor: '#fff',
    pointerEvents: 'auto',
  },
  message: {
    marginTop: 7,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 700,
    color: '#ed5565',
  },
}

class StudioTimeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...DatasetManager.initialState(),
      showMessage: true,
      hidePlugin: false,
    }
    const t = this
    t._onChange = t._onChange.bind(t)
    // receive state changes from the dataset manager
    t.props.datasetManager.on('stateChange', s => t.setState(s, () => {
      if (t.state.datasetsLoaded.length > 0 && t.state.showMessage) t.setState({showMessage: false})
      const relevantDatasets = this.state.datasetsLoaded
        .filter(d => d.aggregateByGrid)
        .filter(d => chained(d, 'data.metadata.format.aggregatedByIntervals') && chained(d, 'data.metadata.format.intervals'))
        .filter(d => !d.splitGridCellByTime)
      if (relevantDatasets.length > 0) {
        const d = relevantDatasets[0]
        t.setState({
          hidePlugin: false,
          values: chained(d, 'data.metadata.format.intervals'),
          from: chained(d, 'data.metadata.format.intervals', []).indexOf(d.aKey),
        })
      } else t.setState({hidePlugin: true})
    }))
  }
  _onChange(aKey) {
    this.props.datasetManager.changeAggregationKey(aKey)
  }
  render() {
    if (this.state.hidePlugin && !this.state.showMessage) return null
    return <div style={styles.pluginOuter}>
      <Plugin style={styles.plugin} datasetManager={this.props.datasetManager} map={this.props.map}>
        {this.state.showMessage ?
          <div style={styles.message}>{Object.values(chained(config, 'datasets.list', {})).length > 0 ? ['Choose a dataset from the list,', <br key="1"/>, 'or drag a JSON file to the map to analyse your own data.'] : ['To analyse your own data,', <br key="2"/>, 'drag your JSON file to the map.']}</div> :
          <Slider
            values={this.state.values}
            from={this.state.from}
            onChange={this._onChange}
            options={{
              grid: true,
              grid_num: 0,
              grid_snap: true,
              hide_min_max: true,
            }}
          />
        }
      </Plugin>
    </div>
  }
}

L.StudioTimeline = L.Control.extend({
  options: {
    datasetManager: null,
    position: 'bottomright',
  },
  initialize: function(options) {
    L.Util.setOptions(this, options)
  },
  onAdd: function(map) {
    return render(<StudioTimeline config={config} map={map} {...this.options}/>)
  },
  onRemove: function(map) {},
})
L.studioTimeline = options => new L.StudioTimeline(options)
