import {aspectOptionsColor, aspectOptionsSize} from '../format/aspects.js'
import {colorScheme} from '../format/colorSchemes.js'
import {mapRange, prepareFilter} from '../plugins/common/data.js'
import {chained} from '../tools/common.js'
import {download} from '../tools/download.js'

const optionsContour = options => ({
  cellContourColor: '#fff',
  cellContourWidth: 2.5,
  cellColorOpacity: options.cellColorOpacity,
})
const optionsNoContour = options => ({
  cellContourWidth: 0,
})
const optionsCentroid = options => ({
  cellCentroidColor: '#000',
  cellCentroidRadius: 1.25,
  cellCentroidOpacity: 1,    
})
const optionsNoCentroid = options => ({
  cellCentroidRadius: 0,
})

const dataMap = aKey => d => {
  if (d.aggregated === undefined) return {id: d.id}
  if (d.aggregated[aKey] === undefined) return {id: d.id}
  return {
    ...d.aggregated[aKey],
    id: d.id,
  }
}

L.StudioGrid = L.Layer.extend({
  options: {
    datasetManager: null,
    cellColorOpacity: .5,
  },
  initialize: function(options) {
    this._map = null
    this._tileZoom = null

    // init options
    L.Util.setOptions(this, options)

    const t = this
    
    // load empty grid
    $.getJSON('/tiles/empty-grid/index.json', data => {
      t._emptyGrid = {
        hide: true,
        aKey: null,
        aggregatedByGrid: true,
        aspectColor: null,
        aspectSize: null,
        data,
        dataRange: null,
        // filename: '',
        // filterByText: {},
        // filterByValue: {},
        id: 'empty-grid',
        title: 'empty-grid',
        url: '/tiles/empty-grid/index.json',
      }
    })

    // init layers
    t._isea3h = L.isea3hLayer({
      // debug: true,
      // silent: false,
      ...optionsNoContour(t.options),
      ...optionsNoCentroid(t.options),
      colorProgressBar: '#ed5565',
      // renderer: 'svg',
    })

    // mouse events
    class InfoPlugin extends L.ISEA3HLayerPlugin {
      onHover(e) {
        this._elementEvent(e, prevE => prevE && prevE.type == 'click' ? undefined : {type: 'mouseOver'})
      }
      onClick(e) {
        this._elementEvent(e, prevE => {
          this.resetCellContourColor()
          this.resetCellContourWidth()
          const unclick = prevE && prevE.type == 'click' && chained(prevE, 'cell.id') == e.cell.id
          if (!unclick) {
            this.setCellContourColor(e.cell, '#000')
            this.setCellContourWidth(e.cell, 3)
          }
          this.render()
          return {type: unclick ? 'mouseOver' : 'click'}
        })
      }
      _elementEvent(e, f) {
        t.options.datasetManager.elementEvent('grid', prevE => {
          let event = f(prevE)
          if (event !== undefined && event !== null) {
            event = {
              ...e,
              target: e.cell,
              layerType: 'grid',
              aggregatedByGrid: true,
              ...event,
            }
            if (this._neighboursTimeout) clearTimeout(this._neighboursTimeout)
            this._neighboursTimeout = setTimeout(() => this.neighbors(e.cell, cells => t.options.datasetManager.elementEventUpdate('grid', event2 => chained(event2, 'cell.id') != e.cell.id || cells === null ? undefined : {dataNotMappedNeighbors: cells.filter(c => c != null).map(c => c.dataNotMapped)})), 500)
          }
          return event
        })
      }
    }
    L.infoPlugin = () => new InfoPlugin()
    t._isea3h.addPlugin(L.infoPlugin())
  },
  loadData: function(datasets) {
    const t = this
    t._datasets = datasets
    if (t._datasets.some(d => d._needsEmptyGrid)) t._datasets.push(t._emptyGrid)
    const prepared = t._datasets.map(t._prepareOptions)
    const preparedDataset = t._datasets.map(t._prepareOptionsDataset)
    t._isea3h.replaceSources(prepared.map((x, i) => ({...x, ...preparedDataset[i]})))
    const tileZooms = t._datasets
      .filter(d => !d._needsEmptyGrid)
      .map(d => chained(d, 'data.metadata.tiles.availableZoom', []))
      .filter(tz => tz.length > 0)
      .map(tz => tz[0])
    t._tileZoom = tileZooms.length == 0 ? 0 : Math.max(...tileZooms)
    t._handleZoom()
  },
  updateData: function(datasets) {
    const t = this
    t._datasets = datasets
    t._isea3h.updateSources(t._datasets.map(t._prepareOptions))
  },
  update: function(o) {
    const t = this
    let os = {}
    if (o.showGridContour) os = {...os, ...optionsContour(t.options)}
    else os = {...os, ...optionsNoContour(t.options)}
    if (o.showGridCentroid) os = {...os, ...optionsCentroid(t.options)}
    else os = {...os, ...optionsNoCentroid(t.options)}
    t._isea3h.update(os)
  },
  _prepareOptionsDataset: function(d) {
    const _dataTransformAggregate = data => {
      // prepare filter
      const filterAway = prepareFilter(d.filterByValue, d.filterByText)
      // aggregate
      const dataByGrid = {}
      for (const d2 of data.data) {
        if (filterAway(d2)) continue
        if (!(d2.isea3hID in dataByGrid)) dataByGrid[d2.isea3hID] = 1
        else dataByGrid[d2.isea3hID]++
      }
      data.data = Object.entries(dataByGrid).map(([id, count]) => ({id, count}))
      return data
    }
    const aggregatedByGrid = chained(d, 'data.metadata.format.aggregatedByGrid')
    const availableZoom = chained(d, 'data.metadata.tiles.availableZoom', [])
    return {
      hide: d.hide,
      url: d.data.url,
      data: !d.data.url && chained(d, 'data') ? {...chained(d, 'data')} : null,
      tileZoom: availableZoom.length > 0 ? availableZoom[0] : null,
      dataKeys: d._dataKeys,
      ...(aggregatedByGrid ? null : {dataTransform: data => _dataTransformAggregate(data)}),
    }
  },
  _prepareOptions: function(d) {
    const [cellColorMin, cellColorMax] = mapRange(
      d.dataRange && d.aspectColor && d.dataRange[d.aspectColor] ? d.dataRange[d.aspectColor].color : [0, null],
      d.dataRangeRelative && d.aspectColor && d.dataRangeRelative[d.aspectColor] ? d.dataRangeRelative[d.aspectColor].color : null)
    const [cellSizeMin, cellSizeMax] = mapRange(
      d.dataRange && d.aspectSize && d.dataRange[d.aspectSize] ? d.dataRange[d.aspectSize].size : [0, null],
      d.dataRangeRelative && d.aspectSize && d.dataRangeRelative[d.aspectSize] ? d.dataRangeRelative[d.aspectSize].size : null)
    const aspectColor = d.aspectColor
    const aspectSize = d.aspectSize
    const colorSchemeBase = d.colorScheme ? colorScheme(d.colorScheme) : null
    return {
      dataMap: chained(d, 'data.metadata.format.aggregatedByIntervals') ? dataMap(d.aKey) : null,
      cellColorKey: aspectColor,
      ...aspectOptionsColor(aspectColor),
      cellColorScale: colorSchemeBase ? colorSchemeBase.scheme : colorScheme('sequential.0'),
      cellColorMin,
      cellColorMax,
      cellSizeKey: aspectSize,
      ...aspectOptionsSize(aspectSize),
      cellSizeMin,
      cellSizeMax,
    }
  },
  exportAsGeoJSON: function(d) {
    const t = this
    const filename = chained(d, 'filename') ? chained(d, 'filename').replace(/\.json$/, '.geojson') : chained(d, 'data.metadata.tiles.createdFromFile') ? chained(d, 'data.metadata.tiles.createdFromFile').replace(/\.json$/, '.geojson') : 'osm-studio.geojson'
    L.isea3hToGeoJSON({
      ...(chained(d, 'data.url') ? {url: chained(d, 'data.url')} : {data: chained(d, 'data.data')}),
      dataKeys: d._dataKeys,
      dataMap: chained(d, 'data.metadata.format.aggregatedByIntervals') ? dataMap(d.aKey) : null,
      pureBBox: t._map.getBounds(),
    }, geoJSON => download(filename, JSON.stringify(geoJSON)))
  },
  _handleZoom: function(map) {
    const t = this
    if (!t._map || t._tileZoom === undefined || t._tileZoom === null || t._tileZoom < 1) return
    if (t._map.getZoom() < t._tileZoom + .5) {
      if (t._map.hasLayer(t._isea3h)) t._isea3h.hideFrom(t._map)
      t.options.datasetManager.hideLayer('grid', true)
    } else {
      if (!t._map.hasLayer(t._isea3h)) t._isea3h.addTo(t._map)
      t.options.datasetManager.hideLayer('grid', false)
    }
  },
  onAdd: function(map) {
    this._map = map
    map.addLayer(this._isea3h)
    map.on('zoom', () => this._handleZoom())
  },
  onRemove: function(map) {
    map.off('zoom', () => this._handleZoom())
    map.removeLayer(this._isea3h)
    this._map = null
  },
})
L.studioGrid = options => new L.StudioGrid(options)
