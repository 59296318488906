import * as uuid from 'uuid'

import {mapFly} from '../components/map.js'
import {aspectCellColorScale, aspectHide, aspectsMapRange, aspectOptionsColor, aspectOptionsSize} from '../format/aspects.js'
import {chained, listMoveDown, listMoveUp} from '../tools/common.js'
import {EventPool} from '../tools/eventPool.js'
import {MathUtils} from '../../shared/javascript/mathUtils.js'

const computeRange = (data, outlierQuartile=.02) => {
  let values
  const dataRange = {}
  for (const k of data.metadata.format.dataKeys) {
    let metadataDataRange
    let vs
    // prepare the data, depending on the metadata available
    const c = chained(data, 'metadata.stats.dataRange')
    if (c && c[JSON.stringify(outlierQuartile)] && c[JSON.stringify(outlierQuartile)][k]) metadataDataRange = c[JSON.stringify(outlierQuartile)][k]
    else {
      if (values === undefined) values = data.metadata.format.aggregatedByIntervals ? data.data.flatMap(d => d.aggregated !== undefined ? Object.values(d.aggregated) : []) : data.data
      vs = values.map(v => v[k])
    }
    // determine min and max
    let colorMin = aspectOptionsColor(k).cellColorMin
    let colorMax = aspectOptionsColor(k).cellColorMax
    let sizeMin = aspectOptionsSize(k).cellSizeMin
    let sizeMax = aspectOptionsSize(k).cellSizeMax
    if (!colorMin || !sizeMin) {
      const m = metadataDataRange != undefined ? metadataDataRange[0] : MathUtils.min(vs, outlierQuartile)
      colorMin = aspectOptionsColor(k).cellColorMin ? aspectOptionsColor(k).cellColorMin : m
      sizeMin = aspectOptionsSize(k).cellSizeMin ? aspectOptionsSize(k).cellSizeMin : m
    }
    if (!colorMax || !sizeMax) {
      const m = metadataDataRange != undefined ? metadataDataRange[1] : MathUtils.max(vs, outlierQuartile)
      colorMax = aspectOptionsColor(k).cellColorMax ? aspectOptionsColor(k).cellColorMax : m
      sizeMax = aspectOptionsSize(k).cellSizeMax ? aspectOptionsSize(k).cellSizeMax : m
    }
    dataRange[k] = {
      color: aspectsMapRange(k)(colorMin, colorMax),
      size: aspectsMapRange(k)(sizeMin, sizeMax),
    }
  }
  return dataRange
}

const isGrid = d => d.aggregateByGrid || chained(d, 'data.metadata.format.aggregatedByGrid')

const seperateDatasets = datasets => {
  const datasetsGrid = []
  const datasetsGeometry = []
  const gridSourcesMapping = []
  for (const [n, d] of datasets.entries()) {
    if (isGrid(d)) {
      datasetsGrid.push(d)
      gridSourcesMapping.push(n)
    } else datasetsGeometry.push(d)
  }
  return [datasetsGrid, datasetsGeometry, gridSourcesMapping]
}

const _datasetWasAdded = (prevDatasets, datasets) => {
  for (const d of datasets) if (prevDatasets.every(prevD => prevD.id != d.id)) return true
  return false
}
const _datasetWasRemoved = (prevDatasets, datasets) => {
  for (const prevD of prevDatasets) if (datasets.every(d => prevD.id != d.id)) return true
  return false
}
const _datasetWasModified = (prevDatasets, datasets, attributes) => {
  for (const d of datasets) if (prevDatasets.filter(prevD => prevD.id == d.id).some(prevD => attributes.some(a => prevD[a] != d[a]))) return true
  return false
}
const _datasetWasReordered = (prevDatasets, datasets) => {
  if (prevDatasets.length != datasets.length) return true
  for (const i in datasets) if (prevDatasets[i].id != datasets[i].id) return true
  return false
}
const _datasetAdded = (prevDatasets, datasets) => {
  const result = []
  for (const d of datasets) if (prevDatasets.every(prevD => prevD.id != d.id)) result.push(d)
  return result
}
const _datasetRemoved = (prevDatasets, datasets) => {
  const result = []
  for (const prevD of prevDatasets) if (datasets.every(d => prevD.id != d.id)) result.push(prevD)
  return result
}
const _datasetModified = (prevDatasets, datasets, attributes) => {
  const result = []
  for (const d of datasets) {
    const prevDs = prevDatasets.filter(prevD => prevD.id == d.id).filter(prevD => attributes.some(a => prevD[a] != d[a]))
    if (prevDs.length > 0) result.push([prevDs[0], d])
  }
  return result
}

let datasetManagerInstance = null

export class DatasetManager {
  constructor(options) {
    if (datasetManagerInstance !== null) return datasetManagerInstance
    datasetManagerInstance = this
    const t = this
    t._options = options
    t._studioGrid = null
    t._studioGeometry = null
    t._gridSourcesMapping = null
    t._enableUpdateInfo = true
    t._state = {
      cellColorOpacity: .5,
      ...DatasetManager.initialState(),
    }
    t._eventPool = new EventPool()
    t._initUpdates()
  }
  static initialState() {
    return {
      datasetsLoaded: [],
      event: {},
      eventPrevious: {},
      hideLayer: {},
      showGridContour: false,
      showGridCentroid: false,
    }
  }
  setStudioGrid(studioGrid) {
    this._studioGrid = studioGrid
  }
  setStudioGeometry(studioGeometry) {
    this._studioGeometry = studioGeometry
  }
  on(type, callback) {
    const t = this
    t._eventPool.on(type, callback)
  }
  changeShowGridContour(showGridContour) {
    const t = this
    t._set({showGridContour})
  }
  changeShowGridCentroid(showGridCentroid) {
    const t = this
    t._set({showGridCentroid})
  }
  addDataset(dataset) {
    const t = this
    t._prepareNewDataset({
      ...dataset,
      id: uuid.v4(),
    }, d => {
      if (t._get('datasetsLoaded', []).length == 0) mapFly(t._options.map, d)
      t._set({datasetsLoaded: [...t._get('datasetsLoaded'), d]})
    })
  }
  modifyDataset(dataset) {
    const t = this
    t._set('datasetsLoaded', datasetsLoaded => datasetsLoaded.map(d => d.id == dataset.id ? dataset : d))
  }
  moveDatasetUp(dataset) {
    const t = this
    const ns = t._get('datasetsLoaded').map((d, n) => d.id == dataset.id ? n : null).filter(x => x != null)
    if (ns.length > 0) t._set('datasetsLoaded', datasetsLoaded => listMoveUp(datasetsLoaded, ns[0]))
  }
  moveDatasetDown(dataset) {
    const t = this
    const ns = t._get('datasetsLoaded').map((d, n) => d.id == dataset.id ? n : null).filter(x => x != null)
    if (ns.length > 0) t._set('datasetsLoaded', datasetsLoaded => listMoveDown(datasetsLoaded, ns[0]))
  }
  removeDataset(dataset) {
    const t = this
    t._set('datasetsLoaded', datasetsLoaded => datasetsLoaded.filter(d => d.id != dataset.id))
  }
  enableUpdateInfo(enableUpdateInfo) {
    const t = this
    t._enableUpdateInfo = enableUpdateInfo
  }
  hideLayer(layerType, hidden) {
    const t = this
    t._set('hideLayer', {
      ...t._get('hideLayer'),
      [layerType]: hidden,
    })
  }
  _prepareEvent(e) {
    if (e && e.layerType == 'grid') {
      if (this._gridSourcesMapping == null) return null
      if (e.data) e.data = Object.fromEntries(e.data.map((d, i) => i < this._gridSourcesMapping.length ? [this._gridSourcesMapping[i], d] : null).filter(x => x != null))
      if (e.data) e.dataNotMapped = Object.fromEntries(e.dataNotMapped.map((d, i) => i < this._gridSourcesMapping.length ? [this._gridSourcesMapping[i], d] : null).filter(x => x != null))
    }
    return e
  }
  elementEvent(layerType, f) {
    const t = this
    const prevE = t._get(`event.${layerType}`, [])
    const e = t._prepareEvent(f(prevE))
    t._set({
      event: {...t._get('event'), [layerType]: e},
      eventPrevious: {...t._get('eventPrevious'), [layerType]: prevE},
    }, t._enableUpdateInfo && e !== undefined)
  }
  elementEventUpdate(layerType, f) {
    const t = this
    const e = t._get(`event.${layerType}`)
    if (e === undefined || e === null) return
    const event = t._get('event')
    event[layerType] = {
      ...e,
      ...f(e),
    }
    t._set({event})
  }
  resetEvent() {
    const t = this
    t._set({
      event: {},
      eventPrevious: t._get('event'),
    })
  }
  changeAggregateByGrid(d, aggregateByGrid) {
    const t = this
    t.modifyDataset({
      ...d,
      aggregateByGrid,
    })
  }
  changeSplitGridCellByTime(d, splitGridCellByTime) {
    const t = this
    t.modifyDataset({
      ...d,
      splitGridCellByTime,
    })
  }
  changeAggregationKey(aKey) {
    const t = this
    t._set('datasetsLoaded', datasetsLoaded => datasetsLoaded.map(d => ({
      ...d,
      aKey,
    })))
  }
  changeAspectColor(d, aspectColor) {
    const t = this
    t.modifyDataset({...d, aspectColor})
  }
  changeColorScheme(d, colorScheme) {
    const t = this
    t.modifyDataset({...d, colorScheme})
  }
  changeAspectSize(d, aspectSize) {
    const t = this
    t.modifyDataset({...d, aspectSize})
  }
  changeDataRangeRelativeColor(d, range) {
    const t = this
    const dataRangeRelative = (d.dataRangeRelative !== undefined) ? {...d.dataRangeRelative} : {}
    if (dataRangeRelative[d.aspectColor] === undefined) dataRangeRelative[d.aspectColor] = {}
    dataRangeRelative[d.aspectColor].color = range
    t.modifyDataset({...d, dataRangeRelative})
  }
  changeDataRangeRelativeSize(d, range) {
    const t = this
    const dataRangeRelative = (d.dataRangeRelative !== undefined) ? {...d.dataRangeRelative} : {}
    if (dataRangeRelative[d.aspectSize] === undefined) dataRangeRelative[d.aspectSize] = {}
    dataRangeRelative[d.aspectSize].size = range
    t.modifyDataset({...d, dataRangeRelative})
  }
  changeFilterByValue(d, filter) {
    const t = this
    t.modifyDataset({...d, filterByValue: {...d.filterByValue, ...filter}})
  }
  changeFilterByText(d, filter) {
    const t = this
    t.modifyDataset({...d, filterByText: {...d.filterByText, ...filter}})
  }
  exportAsGeoJSON(d) {
    if (isGrid(d)) this._studioGrid.exportAsGeoJSON(d)
    else this._studioGeometry.exportAsGeoJSON(d)
  }
  _fire(type, ...es) {
    const t = this
    t._eventPool.fire(type, ...es)
  }
  _set(...args) {
    const t = this
    let state
    let condition = true
    if (args.length == 1 && typeof args[0] == 'object') state = args[0]
    else if (args.length == 2 && typeof args[0] == 'object') {
      state = args[0]
      condition = args[1]
    }
    else if (args.length == 2) state = {[args[0]]: args[1]}
    else if (args.length == 3) {
      state = {[args[0]]: args[1]}
      condition = args[2]
    }
    else return
    if (!condition) return
    const prevState = {}
    for (const [k, v] of Object.entries(t._state)) prevState[k] = Array.isArray(v) ? [...v] : typeof v === 'object' ? {...v} : v
    for (const [k, v] of Object.entries(state)) t._state[k] = typeof v === 'function' ? v(t._get(k)) : v
    t._fire('stateChange', t._state, prevState)
  }
  _get(k) {
    const t = this
    return chained(t._state, k)
  }
  _prepareNewDataset(d, callback) {
    const t = this
    if (d.url && d.data === undefined) $.getJSON(d.url, data => callback(t._prepareNewDataset2({...d, data})))
    else callback(t._prepareNewDataset2(d))
  }
  _prepareNewDataset2(d) {
    d = {
      aggregateByGrid: true,
      splitGridCellByTime: false,
      filterByValue: {},
      filterByText: {},
      ...d,
    }
    if (d.data.metadata.format.aggregatedByGrid) {
      d.aspectColor = d.data.metadata.format.dataKeys[0]
      d.aspectSize = d.data.metadata.format.dataKeys.includes('standardDeviation') && !aspectHide('standardDeviation') ? 'standardDeviation' : 'null'
      d.aKey = d.data.metadata.format.aggregatedByIntervals ? d.data.metadata.format.intervals[Math.max(0, Math.floor(d.data.metadata.format.intervals.length / 2) - 1)] : null
      d.dataRange = computeRange(d.data)
    } else {
      d.aspectColor = 'count'
      d.aspectSize = 'null'
      d.aKey = null
      d.dataRange = {
        count: {
          color: [0, 100],
          size: [0, 100],
        },
      }
    }
    const rangeColor = d.aspectColor && d.dataRange && d.dataRange[d.aspectColor] !== undefined ? d.dataRange[d.aspectColor].color : null
    d.colorScheme = aspectCellColorScale(d.aspectColor, Math.min(this._get('datasetsLoaded', []).length, 5), rangeColor).id
    return d
  }
  _prepareDataset(d) {
    const geometriesAggregated = d.aggregateByGrid && !chained(d, 'data.metadata.format.aggregatedByGrid')
    d._dataKeys = geometriesAggregated ? ['count'] : chained(d, 'data.metadata.format.dataKeys')
    d._needsEmptyGrid = geometriesAggregated
  }
  _prepareDatasetsGrid(datasetsGrid) {
    return datasetsGrid.flatMap(d => {
      if (d.splitGridCellByTime && chained(d, 'data.metadata.format.intervals')) {
        const ds = chained(d, 'data.metadata.format.intervals').map(k => ({...d, aKey: k}))
        let i = 0
        while (true) {
          if (6 * (i - 1) < ds.length && ds.length <= 6 * i) return ds.filter((_, j) => j % i == 0)
          i++
        }
      } else return [d]
    })
  }
  _initUpdates() {
    const t = this
    t.on('stateChange', (state, prevState) => {
      let resetEvent = false
      state.datasetsLoaded.map(t._prepareDataset)
      const [datasetsGrid, datasetsGeometry, gridSourcesMapping] = seperateDatasets(state.datasetsLoaded)
      t._gridSourcesMapping = gridSourcesMapping
      const [prevDatasetsGrid, prevDatasetsGeometry, _] = seperateDatasets(prevState.datasetsLoaded)
      // datasets grid changed
      if (t._studioGrid) {
        let gridOptionsUpdate = false
        let datasetsGridNeedLoad = false
        let datasetsGridNeedLoadWait = false
        let datasetsGridNeedUpdate = false
        for (const k of ['showGridContour', 'showGridCentroid']) if (prevState[k] != state[k]) gridOptionsUpdate = true
        if (!datasetsGridNeedLoad && _datasetWasReordered(prevDatasetsGrid, datasetsGrid)) datasetsGridNeedLoad = true
        if (!datasetsGridNeedLoad && _datasetWasModified(prevDatasetsGrid, datasetsGrid, ['splitGridCellByTime', 'filterByValue'])) datasetsGridNeedLoad = true
        if (!datasetsGridNeedLoad && _datasetWasModified(prevDatasetsGrid, datasetsGrid, ['filterByText'])) datasetsGridNeedLoadWait = true
        if (!datasetsGridNeedLoad && !datasetsGridNeedLoadWait && _datasetWasModified(prevDatasetsGrid, datasetsGrid, ['aspectColor', 'aspectSize', 'aKey', 'colorScheme', 'dataRange', 'dataRangeRelative'])) datasetsGridNeedUpdate = true
        if (gridOptionsUpdate) t._studioGrid.update({
          showGridContour: state.showGridContour,
          showGridCentroid: state.showGridCentroid,
        })
        if (datasetsGridNeedUpdate) t._studioGrid.updateData(t._prepareDatasetsGrid(datasetsGrid))
        if (datasetsGridNeedLoad) t._studioGrid.loadData(t._prepareDatasetsGrid(datasetsGrid))
        if (datasetsGridNeedLoadWait) {
          if (t._datasetsGridNeedLoadWaitTimeout) clearTimeout(t._datasetsGridNeedLoadWaitTimeout)
          t._datasetsGridNeedLoadWaitTimeout = setTimeout(() => t._studioGrid.loadData(t._prepareDatasetsGrid(datasetsGrid)), 500)
        }
        resetEvent = resetEvent || datasetsGridNeedLoad
      }
      // datasets geometry changed
      if (t._studioGeometry) {
        for (const d of _datasetAdded(prevDatasetsGeometry, datasetsGeometry)) {
          t._studioGeometry.loadData(d.id, d.data, d.filename, d.filterByValue, d.filterByText)
          resetEvent = true
        }
        for (const prevD of _datasetRemoved(prevDatasetsGeometry, datasetsGeometry)) {
          t._studioGeometry.removeData(prevD.id)
          resetEvent = true
        }
        for (const [prevD, d] of _datasetModified(prevDatasetsGeometry, datasetsGeometry, ['filterByValue', 'filterByText'])) {
          t._studioGeometry.updateFilter(d.id, d.filterByValue, d.filterByText)
          resetEvent = true
        }
      }
      if (resetEvent) t.resetEvent()
    })
  }
}
