const commonElement = (xs, ys) => {
  if (!Array.isArray(ys)) ys = [ys]
  for (const x of xs) for (const y of ys) if (x == y) return true
  return false
}

const commonElementWithNegation = (xs, ys) => {
  if (xs.length == 0) return false
  const negation = xs[0].startsWith('!')
  if (negation) {
    xs = [...xs]
    xs[0] = xs[0].slice(1)
  }
  return negation ^ commonElement(xs, ys)
}

export const prepareFilter = (filterByValue, filterByText) => {
  const _valuesFilteredAway = []
  if (filterByValue) for (const [k, v] of Object.entries(filterByValue)) if (!v) _valuesFilteredAway.push(k)
  const _filterByText = Object.fromEntries(Object.entries(filterByText).filter(([k, v]) => v && v.length))
  return d => {
    let cont = true
    for (const v of _valuesFilteredAway) if (d.value == v) cont = false
    if (!cont) return true
    cont = true
    for (const [k, v] of Object.entries({
      'country-geometry': {
        key: 'area',
        multi: false,
      },
      'contributor-familiarity': {
        key: 'userFamiliarity',
        multi: true,
      },
    })) if (_filterByText[k] !== undefined) {
      if (d[v.key] === undefined || d[v.key] === null) cont = false
      if (!commonElementWithNegation(_filterByText[k], d[v.key])) cont = false
      if (!cont) return true
    }
    return !cont
  }
}

export const mapRange = ([min, max], dataRangeRelative) => {
  if (dataRangeRelative === null || dataRangeRelative === undefined || min === null || max === null) return [min, max]
  return dataRangeRelative.map(k => min + k * (max - min))
}
