export class EventPool {
  constructor() {
    this._callbacks = {}
  }
  on(type, callback) {
    for (const ty of type.split(' ')) {
      if (this._callbacks[ty] === undefined) this._callbacks[ty] = []
      this._callbacks[ty].push(callback)
    }
  }
  fire(type, ...es) {
    if (this._callbacks[type] === undefined) return
    for (const callback of this._callbacks[type]) callback(...es)
  }
}
