export class MathUtils {
  // replaces Math.min(...xs) but also works for very large array
  static min(xs, outlierQuartile=0) {
    let len = xs.length
    if (outlierQuartile == 0 || len == 0) {
      let min = Infinity
      while (len--) if (xs[len] < min) min = xs[len]
      return min
    } else {
      let lenOutliersToFind = Math.round(len * outlierQuartile)
      let outliers = []
      let threshold = null
      while (len--) {
        if ((threshold === null || xs[len] <= threshold) && xs[len] !== undefined && xs[len] !== null) outliers.push(xs[len])
        if (len === 0 || outliers.length > lenOutliersToFind * 1.7) {
          outliers.sort((a, b) => a - b)
          outliers = outliers.slice(0, lenOutliersToFind)
          threshold = outliers[outliers.length - 1]
        }
      }
      return threshold
    }
  }
  // replaces Math.max(...xs) but also works for very large array
  static max(xs, outlierQuartile=0) {
    let len = xs.length
    if (outlierQuartile == 0 || len == 0) {
      let max = -Infinity
      while (len--) if (xs[len] > max) max = xs[len]
      return max
    } else {
      let lenOutliersToFind = Math.round(len * outlierQuartile)
      let outliers = []
      let threshold = null
      while (len--) {
        if ((threshold === null || xs[len] >= threshold) && xs[len] !== undefined && xs[len] !== null) outliers.push(xs[len])
        if (len === 0 || outliers.length > lenOutliersToFind * 1.7) {
          outliers.sort((a, b) => a - b)
          outliers = outliers.slice(-lenOutliersToFind)
          threshold = outliers[0]
        }
      }
      return threshold
    }
  }
}
