import * as React from 'react'

import {aspectName} from '../../format/aspects.js'
import {ItemSection, Section} from '../../plugins/studioInfo/common/section.js'
import {chained} from '../../tools/common.js'

const styles = {
  filterValueLabel: {
    position: 'relative',
    top: -2,
  },
  filterValueInput: {
    marginLeft: 0,
  },
  filterText: {
    marginTop: 3,
    marginBottom: 3,
    display: 'flex',
  },
  filterTextLabel: {
    marginTop: 2,
    width: 115,
    whiteSpace: 'nowrap',
  },
  filterTextInput: {
    width: 0,
    flexGrow: 1,
    marginLeft: 8,
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid #bbb',
    borderRadius: 0,
  },
  filterTextInputFocus: {
    borderBottom: '1px solid #333',
  },
}

export class SectionFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: null,
    }
  }
  render() {
    const t = this
    let content = 'filters can only be applied to layers that contain geometry information'
    if (t.props.data && !chained(t.props, 'data.data.metadata.format.aggregatedByGrid')) {
      const valueMeaning = Object.entries(chained(t.props.data, 'data.metadata.format.valueMeaning', {})).filter(([k, vs]) => vs !== null)
      content = <div>
        {valueMeaning.flatMap(([k, vs], i) => [
          <ItemSection key={'filter-value-' + k} title={aspectName(k)} isFirst={i == 0}/>,
          ...Object.entries(vs).map(([k2, v]) => <div key={k + '-' + k2}>
            <input
              type="checkbox"
              id={'filter-value-' + k2}
              name={'filter-value-' + k2}
              checked={t.props.data.filterByValue[k2] === undefined ? true: t.props.data.filterByValue[k2]}
              onChange={e => t.props.datasetManager.changeFilterByValue(this.props.data, {
                [k2]: e.target.checked,
              })}
              style={styles.filterValueInput}
            />
            <label htmlFor={'filter-value-' + k2} style={styles.filterValueLabel}>{v}</label>
          </div>)
        ])}
        <ItemSection key="filter-text-location" title="Location" isFirst={valueMeaning.length == 0}/>
        {Object.entries({
          'country-geometry': 'Country geometry',
          'contributor-familiarity': 'Contributor familiarity',
        }).map(([k, v], i) => <div key={'filter-text-' + k} style={styles.filterText}>
          <label htmlFor={'filter-text-' + k} style={styles.filterTextLabel}>{v}</label>
          <input
            type="text"
            id={'filter-text-' + k}
            name={'filter-text-' + k}
            autoComplete="off"
            value={t.props.data.filterByText[k] === undefined ? '' : t.props.data.filterByText[k]}
            onChange={e => t.props.datasetManager.changeFilterByText(this.props.data, {
              [k]: e.target.value.split(',').map(x => x.trim()).filter(x => x.length),
            })}
            onFocus={() => t.setState({focus: 'filter-text-' + k})}
            onBlur={() => t.setState({focus: null})}
            style={{
              ...styles.filterTextInput,
              ...(t.state.focus == 'filter-text-' + k ? styles.filterTextInputFocus : null),
            }}
          />
        </div>)}
      </div>
    }
    return <Section title="Filter" usePages={true} {...this.props}>
      {content}
    </Section>
  }
}
