import * as React from 'react'

import {DatasetManager} from '../format/datasetManager.js'
import {Plugin, render} from '../plugins/common/plugin.js'

const styles = {
  pluginOuter: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: '45%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    pointerEvents: 'none',
  },
  plugin: {
    marginLeft: 0,
    marginRight: 0,
    padding: '12px 30px 3px 30px',
    border: 'none',
    backgroundColor: 'rgb(237, 85, 101, .85)',
    pointerEvents: 'auto',
  },
  message: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 700,
    color: '#fff',
  },
}

class StudioWarning extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...DatasetManager.initialState(),
    }
    const t = this
    // receive state changes from the dataset manager
    t.props.datasetManager.on('stateChange', s => t.setState(s))
  }
  render() {
    if (Object.values(this.state.hideLayer).every(hidden => !hidden)) return null
    return <div style={styles.pluginOuter}>
      <Plugin style={styles.plugin} datasetManager={this.props.datasetManager} map={this.props.map}>
          <div style={styles.message}>Zoom in to show the data</div>
      </Plugin>
    </div>
  }
}

L.StudioWarning = L.Control.extend({
  options: {
    datasetManager: null,
    position: 'topright',
  },
  initialize: function(options) {
    L.Util.setOptions(this, options)
  },
  onAdd: function(map) {
    return render(<StudioWarning config={config} map={map} {...this.options}/>)
  },
  onRemove: function(map) {},
})
L.studioWarning = options => new L.StudioWarning(options)
